import React from 'react'

const PreRequis = () => {
    return (
        <div className="course-item">
            <div className="course-inner">
                <div className="course-content">
                    <h4>Publics & pré-requis :</h4>
                    <ul className="lab-ul">
                        <li>
                            <i className="icofont-tick-mark"></i>Public ciblé :
                            <ul>
                                <li>- Commerciaux</li>
                                <li>- Collaborateurs commerciaux</li>
                                <li>- Technico-commerciaux</li>
                                <li>- Téléconseillers</li>
                                <li>- Télévendeurs</li>
                            </ul>
                        </li>
                        <li>
                            <i className="icofont-tick-mark"></i>Pré-requis :
                            <ul>
                                <li>Il est recommandé d'avoir quelques notions commerciales pour suivre la formation télévente en récéption d'appels.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );

}
export default PreRequis