import Rating from "../sidebar/rating";
import {Link} from "react-router-dom";

// const title = "Télévente en réception d'appels";
// const desc = "Le plus impressionnant est la collection de cours universitaires en ligne Share Me";
// const author = "Rajib Raj";
const reviewCount = "03 Commentaires";
const videoLink = "https://www.youtube-nocookie.com/embed/jP649ZHA8Tg";

const categoryList = [
  {
    link: "#",
    text: "Télévente",
    className: "course-cate",
  },
  {
    link: "#",
    text: "Réduction 30%",
    className: "course-offer",
  },
];

const PageHeaderTwo = ({
  title,
  description,
  formateur,
  typeFormation,
  pdf,
}) => {
  return (
    <div className="pageheader-section style-2">
      <div className="container">
        <div className="row justify-content-center justify-content-lg-between align-items-center flex-row-reverse">
          <div className="col-lg-7 col-12">
            <div className="pageheader-thumb">
              <img
                src="assets/images/pageheader/02.jpg"
                alt="rajibraj91"
                className="w-100"
              />
              <a href="javascript: ;" className="video-button popup">
                <i className="icofont-ui-play"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="pageheader-content">
              <div className="course-category">
                {/*{categoryList.map((val, i) => (*/}
                {/*    <a href={val.link} className={val.className} key={i}>{val.text}</a>*/}
                {/*))}*/}
                <a href="" className="course-cate">
                  {typeFormation}
                </a>
                <a
                    href={"/formations/"+pdf}
                    className="course-offer"
                    target="_blank"
                >
                  Telecherger le PDF
                </a>
              </div>
              <h2 className="phs-title">{title}</h2>
              <p className="phs-desc">{description}</p>



              {/*<div className="phs-thumb">*/}
              {/*  <img src="assets/images/pageheader/03.jpg" alt="rajibraj91" />*/}
              {/*  <span>{formateur}</span>*/}
              {/*  <div className="course-reiew">*/}
              {/*    <Rating />*/}
              {/*    <span className="ratting-count">{reviewCount}</span>*/}
              {/*  </div>*/}
              {/*</div>*/}


            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeaderTwo;
