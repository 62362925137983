

const title = "Catégories des formations :";


const cscContentList = [
    {
        link: '#',
        left: 'Développement personnel',
        right: '30',
    },
    {
        link: '#',
        left: 'Photographie',
        right: '20',
    },
    {
        link: '#',
        left: 'Enseignement et universitaires',
        right: '93',
    },
    {
        link: '#',
        left: 'Art et désign',
        right: '54',
    },
    {
        link: '#',
        left: 'Entreprise',
        right: '28',
    },
    {
        link: '#',
        left: 'Science des données',
        right: '30',
    },
    {
        link: '#',
        left: 'Développement',
        right: '38',
    },
    {
        link: '#',
        left: 'Finance',
        right: '75',
    },
    {
        link: '#',
        left: 'Santé et Forme',
        right: '89',
    },
    {
        link: '#',
        left: 'Mode de vie',
        right: '37',
    },
    {
        link: '#',
        left: 'Commercialisation',
        right: '18',
    },
    {
        link: '#',
        left: 'Musique',
        right: '20',
    },
]


const CourseSideCetagory = () => {
    return (
        <div className="course-side-detail">
            <div className="csd-title">
                <div className="csdt-left">
                    <h4 className="mb-0">{title}</h4>
                </div>
            </div>
            <div className="csc-content">
                <div className="csdc-lists">
                    <ul className="lab-ul">
                        {cscContentList.map((val, i) => (
                            <li key={i}>
                                <div className="csdc-left"><a href={val.link}>{val.left}</a></div>
                                <div className="csdc-right">{val.right}</div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
 
export default CourseSideCetagory;