import React from "react";

const ContenuCours = () => {
  return (
    <>
      {/*partie 1  */}

      <div className="course-video">
        <div className="course-video-title">
          <h4>
            1ère partie : Généralités sur la prospection téléphonique :{" "}
            <span style={{ cursor: "pointer" }}> 1 Jour</span>
          </h4>
        </div>
        <div className="course-video-content">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <div className="accordion-header" id="accordion01">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist1"
                  aria-expanded="true"
                  aria-controls="videolist1"
                >
                  <span style={{ cursor: "pointer" }}>
                    Etape primordiale du tunnel de vente :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist1"
                className="accordion-collapse collapse show"
                aria-labelledby="accordion01"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">L’appel à froid</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Sollicitation via Linkedin ou par mail
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Le tunnel de contenu</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      S’organiser dans une logique de tunnel
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion02">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist2"
                  aria-expanded="false"
                  aria-controls="videolist2"
                >
                  <span style={{ cursor: "pointer" }}>
                    Les motivations pour la prospection téléphonique :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist2"
                className="accordion-collapse collapse"
                aria-labelledby="accordion02"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Des chiffres encourageants
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Les facteurs de succès des télévendeurs
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Comment se motiver ?</div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion03">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist3"
                  aria-expanded="false"
                  aria-controls="videolist3"
                >
                  <span style={{ cursor: "pointer" }}>
                    Préparer sa prospection téléphonique :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist3"
                className="accordion-collapse collapse"
                aria-labelledby="accordion03"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Les 4 règles de la prospection téléphonique
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Le contact au téléphone
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Gérer l’approche téléphonique
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*partie 2*/}

      <div className="course-video">
        <div className="course-video-title">
          <h4>
            2ème partie : la communication au téléphone (partie 1) :{" "}
            <span style={{ cursor: "pointer" }}> 2 Jours</span>
          </h4>
        </div>
        <div className="course-video-content">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <div className="accordion-header" id="accordion04">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist4"
                  aria-expanded="true"
                  aria-controls="videolist4"
                >
                  <span style={{ cursor: "pointer" }}>
                    Le schéma de la communication
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion05">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist5"
                  aria-expanded="false"
                  aria-controls="videolist5"
                >
                  <span style={{ cursor: "pointer" }}>
                    Les facteurs clés d’une communication réussie :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist5"
                className="accordion-collapse collapse"
                aria-labelledby="accordion05"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Test de Leavitt</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Questions ouvertes/fermées
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">La règle des 4 « C »</div>
                  </li>
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Les pièges à éviter</div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion06">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist6"
                  aria-expanded="false"
                  aria-controls="videolist6"
                >
                  <span style={{ cursor: "pointer" }}>
                    Différences entre communication en face à face et au
                    téléphone :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist6"
                className="accordion-collapse collapse"
                aria-labelledby="accordion06"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Les premières secondes au téléphone
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Les mots, les gestes, la posture, la respiration
                      L’utilisation de la voix en prospection téléphonique
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Le débit, l’intonation, le rythme, la chaleur
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      L’utilisation de la voix au cours des différentes phases
                      de la vente Le vocabulaire positif
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Les types de langage</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Les interdits et les mots à éviter/à utiliser Les
                      références visuelles
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion07">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist7"
                  aria-expanded="false"
                  aria-controls="videolist7"
                >
                  <span style={{ cursor: "pointer" }}>
                    Espace-temps et cerveau reptiliens :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist7"
                className="accordion-collapse collapse"
                aria-labelledby="accordion07"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Occuper l’espace-temps
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Connaître le cerveau reptilien
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Comprendre les envies et les douleurs de votre
                      interlocuteur Les 11 règles d’or d’une communication
                      réussie
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      L’utilisation de la voix au cours des différentes phases
                      de la vente Le vocabulaire positif
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Les types de langage</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Les interdits et les mots à éviter/à utiliser Les
                      références visuelles
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion08">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist8"
                  aria-expanded="false"
                  aria-controls="videolist8"
                >
                  <span style={{ cursor: "pointer" }}>
                    Les 11 règles d’or d’une communication réussie
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*etape3*/}
      <div className="course-video">
        <div className="course-video-title">
          <h4>
            3ème partie : la communication au téléphone (partie 2) :{" "}
            <span style={{ cursor: "pointer" }}> 2 Jours</span>
          </h4>
        </div>
        <div className="course-video-content">
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <div className="accordion-header" id="accordion09">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist9"
                  aria-expanded="true"
                  aria-controls="videolist9"
                >
                  <span style={{ cursor: "pointer" }}>
                    La vente est une science humaine
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>

              <div
                id="videolist9"
                className="accordion-collapse collapse"
                aria-labelledby="accordion09"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Une relation humaine</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Bien communiquer pour bien vendre
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Appliquer l’analyse transactionnelle
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion10">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist10"
                  aria-expanded="false"
                  aria-controls="videolist10"
                >
                  <span style={{ cursor: "pointer" }}>
                    Savoir passionner au téléphone :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist10"
                className="accordion-collapse collapse"
                aria-labelledby="accordion10"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Savoir raconter des histoires pendant une vente
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Structure d’une histoire de vente
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Créer des émotions</div>
                  </li>
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Les techniques pour raconter une bonne histoire qui fait
                      vendre
                    </div>
                  </li>
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Les catégories d’histoires spécifiques à la vente
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion11">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist11"
                  aria-expanded="false"
                  aria-controls="videolist11"
                >
                  <span style={{ cursor: "pointer" }}>
                    Le dételage : conversation libre :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist11"
                className="accordion-collapse collapse"
                aria-labelledby="accordion11"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Créer un lien</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Entretenir une conversation
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion12">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist12"
                  aria-expanded="false"
                  aria-controls="videolist12"
                >
                  <span style={{ cursor: "pointer" }}>
                    Utilisation de la psychologie :
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
              <div
                id="videolist12"
                className="accordion-collapse collapse"
                aria-labelledby="accordion12"
                data-bs-parent="#accordionExample"
              >
                <ul className="lab-ul video-item-list">
                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      La force du mot « parce que »
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Le principe de réciprocité
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Effet de sympathie</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">
                      Technique de l’étiquetage
                    </div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Les types de langage</div>
                  </li>

                  <li className=" d-flex flex-wrap justify-content-between">
                    <div className="video-item-title">Ethique et moralité</div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="accordion-item">
              <div className="accordion-header" id="accordion08">
                <button
                  className="d-flex flex-wrap justify-content-between"
                  data-bs-toggle="collapse"
                  data-bs-target="#videolist8"
                  aria-expanded="false"
                  aria-controls="videolist8"
                >
                  <span style={{ cursor: "pointer" }}>
                    Les 11 règles d’or d’une communication réussie
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <i className="fa fa-solid fa-caret-down"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContenuCours;
