import { Link } from "react-router-dom";

const title = "Agenda :";
const paymentTitle = "Paiement sécurisé:";
const shareTitle = "Partagez ce cours :";
const btnText = "VOIR TOUTES LES DATES";


const csdcList = [
    {
        iconName: 'icofont-book-alt',
        leftText: 'Référence',
        rightText: 'TEL.08',
    },
    {
        iconName: 'icofont-ui-alarm',
        leftText: 'Durée du cours',
        rightText: '4 jours',
    },
    {
        iconName: 'icofont-price',
        leftText: 'Prix',
        rightText: '08',
    },
]

const CourseAgenda = () => {
    return (
        <div className="course-side-detail">
            <div className="csd-title">
                <div className="csdt-left">
                    <h4 className="mb-0">{title}</h4>
                </div>
            </div>
            <div className="csc-content">
                <div className="csdc-lists">
                    <table className="table table-hover">
                        <tbody>
                            <tr>
                                <td> 
                                    Du 28-09-2022 
                                    <br/>
                                    Au 29-09-2022
                                </td>
                                <td>LILLE</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td> 
                                    Du 28-09-2022 
                                    <br/>
                                    Au 29-09-2022
                                </td>
                                <td>LILLE</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td> 
                                    Du 28-09-2022 
                                    <br/>
                                    Au 29-09-2022
                                </td>
                                <td>LYON</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td> 
                                    Du 28-09-2022 
                                    <br/>
                                    Au 29-09-2022
                                </td>
                                <td>BORDEAUX</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                
                                <td colSpan="3" >
                                <center>
                                    <button style={{
                                            borderRadius: 20,
                                            background: "#f16931",
                                            color: "#fff",
                                            fontSize: 13,
                                            fontWeight: "bold",
                                            letterSpacing: 1,
                                            textAlign: "center",
                                            padding: 12,
                                            display: "inline-block",
                                        }}>
                                        VOIR TOUTES LES DATES
                                    </button>
                                    </center>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CourseAgenda;
