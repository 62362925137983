import React from "react";

const ContenuAppelsSortants = () => {
  return (
    <div className="course-video">
      <div className="course-video-title">
        <h4>
          1ère partie : Généralités sur la prospection téléphonique : 1 jour
        </h4>
      </div>
      <div className="course-video-content">
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <div className="accordion-header" id="accordion04">
              <button
                className="d-flex flex-wrap justify-content-between"
                data-bs-toggle="collapse"
                data-bs-target="#videolist1"
                aria-expanded="true"
                aria-controls="videolist1"
              >
                <span style={{ cursor: "pointer" }}>
                  Enjeu majeur commercial :
                </span>
                <span style={{ cursor: "pointer" }}>
                  <i className="fa fa-solid fa-caret-down"></i>
                </span>
              </button>
            </div>
            <div
              id="videolist1"
              className="accordion-collapse collapse show"
              aria-labelledby="accordion01"
              data-bs-parent="#accordionExample"
            >
              <ul className="lab-ul video-item-list">
                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">L’appel à froid</div>
                </li>

                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    Un acte vital pour l’entreprise
                  </div>
                </li>
                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">Le gain d’argent</div>
                </li>
                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    Les catégories de commerciaux
                  </div>
                </li>
                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">“B to B” et “B to C”</div>
                </li>
              </ul>
            </div>
          </div>
          <div className="accordion-item">
            <div className="accordion-header" id="accordion01">
              <button
                className="d-flex flex-wrap justify-content-between"
                data-bs-toggle="collapse"
                data-bs-target="#videolist1"
                aria-expanded="true"
                aria-controls="videolist1"
              >
                <span style={{ cursor: "pointer" }}>
                  Etape primordiale du tunnel de vente :
                </span>
                <span style={{ cursor: "pointer" }}>
                  <i className="fa fa-solid fa-caret-down"></i>
                </span>
              </button>
            </div>
            <div
              id="videolist1"
              className="accordion-collapse collapse "
              aria-labelledby="accordion01"
              data-bs-parent="#accordionExample"
            >
              <ul className="lab-ul video-item-list">
                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">L’appel à froid</div>
                </li>

                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    Sollicitation via Linkedin ou par mail
                  </div>
                </li>

                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">Le tunnel de contenu</div>
                </li>

                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    S’organiser dans une logique de tunnel
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header" id="accordion02">
              <button
                className="d-flex flex-wrap justify-content-between"
                data-bs-toggle="collapse"
                data-bs-target="#videolist2"
                aria-expanded="false"
                aria-controls="videolist2"
              >
                <span style={{ cursor: "pointer" }}>
                  Les motivations pour la prospection téléphonique :
                </span>
                <span style={{ cursor: "pointer" }}>
                  <i className="fa fa-solid fa-caret-down"></i>
                </span>
              </button>
            </div>
            <div
              id="videolist2"
              className="accordion-collapse collapse"
              aria-labelledby="accordion02"
              data-bs-parent="#accordionExample"
            >
              <ul className="lab-ul video-item-list">
                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    Des chiffres encourageants
                  </div>
                </li>

                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    Les facteurs de succès des télévendeurs
                  </div>
                </li>

                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">Comment se motiver ?</div>
                </li>
              </ul>
            </div>
          </div>

          <div className="accordion-item">
            <div className="accordion-header" id="accordion03">
              <button
                className="d-flex flex-wrap justify-content-between"
                data-bs-toggle="collapse"
                data-bs-target="#videolist3"
                aria-expanded="false"
                aria-controls="videolist3"
              >
                <span style={{ cursor: "pointer" }}>
                  Préparer sa prospection téléphonique :
                </span>
                <span style={{ cursor: "pointer" }}>
                  <i className="fa fa-solid fa-caret-down"></i>
                </span>
              </button>
            </div>
            <div
              id="videolist3"
              className="accordion-collapse collapse "
              aria-labelledby="accordion03"
              data-bs-parent="#accordionExample"
            >
              <ul className="lab-ul video-item-list">
                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    Les 4 règles de la prospection téléphonique
                  </div>
                </li>

                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    Le contact au téléphone
                  </div>
                </li>

                <li className=" d-flex flex-wrap justify-content-between">
                  <div className="video-item-title">
                    Gérer l’approche téléphonique
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContenuAppelsSortants;
