


const GoogleMap = () => {
    return (
        <div className="map-area">
            <div className="maps">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.8355658756263!2d2.276423315152511!3d48.84227510979613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6700a2afc6a17%3A0xb0e69e004d92fb2b!2sAll.%20du%20Commandant%20Raynal%2C%2075015%20Paris%2C%20France!5e0!3m2!1sfr!2sma!4v1663606105455!5m2!1sfr!2sma"></iframe>
            </div>
        </div>
    );
}
 
export default GoogleMap;