import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";
import AboutSideBar from "../component/section/about";


const AboutPage = () => {
    return ( 
        <Fragment>
            <Header />
            <PageHeader title={'À PROPOS DE NOUS'} curPage={'About'} />
            <AboutSideBar/>
            {/*<Student />*/}
            {/*<Instructor />*/}
            {/*<Skill />*/}
            {/*<AchievementTwo />*/}
            {/*<Blog />*/}
            <Footer />
        </Fragment>
    );
}

export default AboutPage;
 