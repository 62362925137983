import React from 'react'

const DateLieuxTable = () => {
    return (
        <div className="course-item">
            <div className="course-inner">
                <div className="course-content">
                    <h4>Dates & Lieux :</h4>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">DÉBUT</th>
                                <th scope="col">FIN</th>
                                <th scope="col">
                                    <div>
                                        <select name="lieuFormation" id="lieuFormation">
                                            <option value="ALLVILLE">--VILLE</option>
                                            <option value="LILLE">LILLE</option>
                                            <option value="CLASSE_VIRTUELLE">CLASSE VIRTUELLE - FORMATION À DISTANCE</option>
                                            <option value="MIXTE_VISIO">MIXTE VISIO / PRESENTIEL PARIS</option>
                                            <option value="BORDEAUX">BORDEAUX</option>
                                            <option value="LYON">LYON</option>
                                            <option value="STRASBOURG">STRASBOURG</option>
                                        </select>
                                    </div>
                                </th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>LILLE</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>CLASSE VIRTUELLE – FORMATION À DISTANCE</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>MIXTE VISIO / PRESENTIEL PARIS</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>MIXTE VISIO / PRESENTIEL PARIS</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>LILLE</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>CLASSE VIRTUELLE – FORMATION À DISTANCE</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>BORDEAUX</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>CLASSE VIRTUELLE – FORMATION À DISTANCE</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>LYON</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>LYON</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <th>28-09-2022</th>
                                <td>29-09-2022</td>
                                <td>STRASBOURG</td>
                                <td>
                                    <button style={{
                                        borderRadius: 20,
                                        background: "#f16931",
                                        color: "#fff",
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        letterSpacing: 1,
                                        textAlign: "center",
                                        padding: 12,
                                        display: "inline-block",
                                    }}>
                                        RÉSERVER
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    );
}

export default DateLieuxTable;