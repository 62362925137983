import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory__2";
import CourseSideDetail from "../component/sidebar/course-detail___2";
import Respond from "../component/sidebar/respond";

const CourseDetails = () => {
  return (
    <>
        <Header />
        <PageHeaderTwo />
        <div className="course-single-section padding-tb section-bg">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="main-part">
                            <div className="course-item">
                                <div className="course-inner">
                                    <div className="course-content">
                                        <h3>Aperçu du cours</h3>
                                        <p>Dans ce cours, vous passerez des principes fondamentaux et des concepts de la modélisation des données à un certain nombre de meilleures pratiques et techniques dont vous aurez besoin pour créer des modèles de données dans votre organisation. Vous en trouverez beaucoup À la fin du cours, vous serez prêt non seulement à mettre ces principes en pratique, mais également à prendre les décisions clés en matière de modélisation et de conception des données requises par la modélisation des données d'information qui transcendent les détails. des boulons qui clairement la clé couvraient le parcours et les modèles de conception.</p>
                                        <h4>Ce que vous apprendrez dans ce cours :</h4>
                                        <ul className="lab-ul">
                                            <li><i className="icofont-tick-mark"></i>Prêt à commencer à travailler sur des projets de modélisation de données du monde réel.</li>
                                            <li><i className="icofont-tick-mark"></i>Responsabilités élargies dans le cadre d'un rôle existant.</li>
                                            <li><i className="icofont-tick-mark"></i>Be able to create Flyers, Brochures, Advertisements.</li>
                                            <li><i className="icofont-tick-mark"></i>Être capable de créer des Flyers, Brochures, Publicités.</li>
                                            <li><i className="icofont-tick-mark"></i>Travailler avec la couleur et les dégradés et les grilles.</li>
                                        </ul>
                                        <p>Dans ce cours, vous passerez des principes fondamentaux et des concepts de la modélisation des données à un certain nombre de meilleures pratiques et techniques dont vous aurez besoin pour créer des modèles de données dans votre organisation. Vous trouverez de nombreux exemples qui montrent clairement que la clé a couvert le cours.</p>
                                        <p>À la fin du cours, vous serez prêt non seulement à appliquer ces principes, mais également à prendre les décisions de modélisation et de conception des données clés requises par la modélisation des données d'information qui transcendent les rouages ​​qui sont clairement la clé. couvert le cours et les modèles de conception.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="course-video">
                                <div className="course-video-title">
                                    <h4>Le contenu du cours</h4>
                                </div>
                                <div className="course-video-content">
                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="accordion01">
                                                <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist1" aria-expanded="true" aria-controls="videolist1"><span>1.Introduction</span> <span>5 leçons, 17:37</span> </button>
                                            </div>
                                            <div id="videolist1" className="accordion-collapse collapse show" aria-labelledby="accordion01" data-bs-parent="#accordionExample">
                                                <ul className="lab-ul video-item-list">
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">1.1 Bienvenue au cours 02:30 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">1.2 Comment configurer votre espace de travail Photoshop 08:33 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">1.3 Outils Photoshop essentiels 03:38 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">1.4 Trouver l'inspiration 02:30 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">1.5 Choisir votre format 03:48 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-header" id="accordion02">
                                                <button className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist2" aria-expanded="true" aria-controls="videolist2"> <span>2.Comment créer de l'art multimédia dans Adobe Photoshop</span> <span>5 leçons, 52:15</span> </button>
                                            </div>
                                            <div id="videolist2" className="accordion-collapse collapse" aria-labelledby="accordion02" data-bs-parent="#accordionExample">
                                                <ul className="lab-ul video-item-list">
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">2.1 Utilisation des calques de réglage 06:20 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">2.2 Construire la composition 07:33 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">2.3 Effets d'éclairage Photoshop 06:30 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">2.4 Peinture numérique à l'aide de pinceaux Photoshop 08:34 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                    <li className=" d-flex flex-wrap justify-content-between">
                                                        <div className="video-item-title">2.5 Finaliser les détails 10:30 minutes</div>
                                                        <div className="video-item-icon"><a href="https://www.youtube-nocookie.com/embed/jP649ZHA8Tg" className="popup" target="_blank"><i className="icofont-play-alt-2"></i></a></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Author />
                            <Comment />
                            <Respond />
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="sidebar-part">
                            <CourseSideDetail />
                            <CourseSideCetagory />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
)}

export default CourseDetails