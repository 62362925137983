import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";

const subTitle = "FORMATIONS EN VEDETTE";
const title = "Choisissez une formation pour commencer";

const courseList = [
  {
    imgUrl: "assets/images/course/01.jpg",
    imgAlt:
      "Formation télé-vendeur : Développez vos compétences en communication et en appel sortant",
    price: "2550€",
    cate: "Techniques de communication",
    reviewCount: "03 commentaires",
    title:
      "FORMATION TÉLÉ-VENDEUR : Développez vos compétences en communication et en appel sortant",
    totalLeson: "3 parties",
    schdule: "Cours présentiel",
    authorImgUrl: "assets/images/course/author/01.jpg",
    authorImgAlt: "Mr Ahmed MIR",
    authorName: "Mr Ahmed MIR",
    btnText: "Lire la suite",
    lien: "/formation-tele-vendeur",
  },

  {
    imgUrl: "assets/images/course/01-1.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    price: "2550€",
    cate: "Relation Clients",
    reviewCount: "03 commentaires",
    title:
      "FORMATION RELATION CLIENTS DANS UN HELP DESK: Développer la communication d’un support technique.",
    totalLeson: "3 parties",
    schdule: "Cours présentiel",
    authorImgUrl: "assets/images/course/author/01.jpg",
    authorImgAlt: "Mr Ahmed MIR",
    authorName: "Mr Ahmed MIR",
    btnText: "Lire la suite",
    lien: "/formation-helpdesk",
  },
  {
    imgUrl: "assets/images/course/05.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    price: "2550€",
    cate: "Relation Clients",
    reviewCount: "03 commentaires",
    title:
      "MAITRISEZ LA COMMUNICATION PAR TELEPHONE : Appels sortants vers des prospects",
    totalLeson: "3 modules",
    schdule: "Cours présentiel",
    authorImgUrl: "assets/images/course/author/01.jpg",
    authorImgAlt: "Auteur du cours rajibraj91 rajibraj",
    authorName: "Mr Ahmed MIR",
    btnText: "Lire la suite",
    lien: "/formation-appels-sortants",
  },
];

const Course = () => {
  return (
    <div className="course-section padding-tb section-bg">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-xl-3 row-cols-md-2 row-cols-1">
            {courseList.map((val, i) => (
              <div className="col" key={i}>
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="course-content">
                      <div className="course-price">{val.price}</div>
                      <div className="course-category">
                        <div className="course-cate">
                          <a href="#">{val.cate}</a>
                        </div>
                        <div className="course-reiew">
                          <Rating />
                          <span className="ratting-count">
                            {" "}
                            {val.reviewCount}
                          </span>
                        </div>
                      </div>
                      <Link to={val.lien}>
                        <h4>{val.title}</h4>
                      </Link>
                      <div className="course-details">
                        <div className="couse-count" style={{fontFamily:  'AvenirNextReg'}}>
                          <i className="icofont-video-alt"></i> {val.totalLeson}
                        </div>
                        <div className="couse-topic" style={{fontFamily:  'AvenirNextReg'}}>
                          <i className="icofont-signal"></i> {val.schdule}
                        </div>
                      </div>

                      {/*<div className="course-footer">*/}
                      {/*  <div className="course-author">*/}
                      {/*    <img*/}
                      {/*      src={`${val.authorImgUrl}`}*/}
                      {/*      alt={`${val.authorImgAlt}`}*/}
                      {/*    />*/}
                      {/*    <Link to="/team-single" className="ca-name">*/}
                      {/*      {val.authorName}*/}
                      {/*    </Link>*/}
                      {/*  </div>*/}
                      {/*  <div className="course-btn">*/}
                      {/*    <Link to={val.lien} className="lab-btn-text">*/}
                      {/*      {val.btnText}{" "}*/}
                      {/*      <i className="icofont-external-link"></i>*/}
                      {/*    </Link>*/}
                      {/*  </div>*/}
                      {/*</div>*/}


                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
