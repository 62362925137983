import {Fragment} from 'react';

import Header from '../component/layout/header';
import PageHeader from '../component/layout/pageheader';
import Footer from '../component/layout/footer';
import CourseSideCetagory from "../component/sidebar/course-cetagory__2";
import ContenuFAQ from '../CompoParts/ContenuFAQ';


const FAQ = () => {
    return(
        <Fragment>
            <Header />
            <PageHeader title={'Foire aux questions'} curPage={'FAQ'} />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content">
                        <div className="col-lg-12">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Foire aux questions</h3>
                                            <p>Découvrez dans notre rubrique FAQ les questions les plus fréquemment posées concernant la formation professionnelle. Le COTRACALL vous apporte ici des précisions pour vous guider dans votre choix de formation, sur de nombreuses thématiques. Pour plus d'informations, vous avez la possibilité de poser votre question directement dans la rubrique que vous souhaitez consulter.</p>
                                            {/**Les questions : */}

                                            <ContenuFAQ/>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-4">*/}
                        {/*    <div className="sidebar-part">*/}
                        {/*        <CourseSideCetagory />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default FAQ