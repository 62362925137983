import { Link } from "react-router-dom";

const price = "89";
const title = "Centre de formation :";
const paymentTitle = "Paiement sécurisé:";
const shareTitle = "Partagez ce cours :";
const btnText = "S'incrire maintenant";

// const csdcList = [
//     {
//         iconName: 'icofont-book-alt',
//         leftText: 'Référence',
//         rightText: 'TEL.08',
//     },
//     {
//         iconName: 'icofont-ui-alarm',
//         leftText: 'Durée du cours',
//         rightText: '4 jours',
//     },
//     {
//         iconName: 'icofont-price',
//         leftText: 'Prix',
//         rightText: '08',
//     },
// ]

const socialList = [
  {
    siteLink: "#",
    iconName: "icofont-twitter",
    className: "twitter",
  },
  {
    siteLink: "#",
    iconName: "icofont-vimeo",
    className: "vimeo",
  },
  {
    siteLink: "#",
    iconName: "icofont-rss",
    className: "rss",
  },
];

const CourseSideDetail = ({ csdcList }) => {
  return (
    <div className="course-side-detail">
      <div className="csd-title">
        <div className="csdt-left">
          <h4 className="mb-0">{title}</h4>
        </div>
      </div>
      <div className="csd-content">
        <div className="csdc-lists">
          <ul className="lab-ul">
            {csdcList.map((val, i) => (
              <li key={i}>
                <div className="csdc-left">
                  <i className={val.iconName}></i>
                  {val.leftText}
                </div>
                <div className="csdc-right">{val.rightText}</div>
              </li>
            ))}
          </ul>
        </div>

        <div className="course-enroll">
          <center>
            <Link to="/signup" className="lab-btn">
              <span>{btnText}</span>
            </Link>
          </center>
        </div>

        <div className="sidebar-social">
          <div className="ss-title">
            <h6>{shareTitle}</h6>
          </div>
          <div className="ss-content">
            <ul className="lab-ul">
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink} className={val.className}>
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSideDetail;
