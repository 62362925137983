import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory__2";
import CourseSideDetail from "../component/sidebar/course-detail___2";
import Respond from "../component/sidebar/respond";
import PreRequis from "../CompoParts/PreRequis";
import ContenuCours from "../CompoParts/ContenuCours";
import DateLieuxTable from "../CompoParts/DateLieuxTable";
import CourseAgenda from "../CompoParts/CourseAgenda";

const FormationTeleVendeur = () => {
  return (
    <Fragment>
      <Header />
      <PageHeaderTwo
        title="Formation télé-vendeur : Développez vos compétences en communication et en appel sortant"
        description="La télévente est souvent associée aux call center (ou centre d'appels ou encore plateforme d'appels téléphoniques) qui centralisent l'ensemble des contacts téléphoniques et assurent un traitement standard pour chaque appel. Même si les entreprises tendent à assurer une gestion commerciale des demandes via une interface online, nombre d'entreprises disposent encore d'un service commercial dédié à la télévente en réception d'appel. Cette formation sur la télévente en réception d'appels vous permettra de revoir les fondamentaux de la vente dans le cadre d'un appel entrant."
        formateur="Mr Ahmed MIR"
        typeFormation="Techniques de communication"
        pdf="FORMATION COTRACALL-Forces de Vente sur Appels Sortant- CPF 2.pdf"
      />

      <div className="course-single-section padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h3>
                        Formation télé-vendeur : Développez vos compétences en
                        communication et en appel sortant
                      </h3>
                      <p>
                        La télévente est souvent associée aux call center (ou
                        centre d'appels ou encore plateforme d'appels
                        téléphoniques) qui centralisent l'ensemble des contacts
                        téléphoniques et assurent un traitement standard pour
                        chaque appel. Même si les entreprises tendent à assurer
                        une gestion commerciale des demandes via une interface
                        online, nombre d'entreprises disposent encore d'un
                        service commercial dédié à la télévente en réception
                        d'appel. Cette formation sur la télévente en réception
                        d'appels vous permettra de revoir les fondamentaux de la
                        vente dans le cadre d'un appel entrant.
                      </p>

                      <h4>Personnes concernées :</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>Téléconseillers,
                          télévendeurs, conseillers clientèle à distance,
                          assistant(e)s ADV, vendeurs, commerciaux,
                          technico-commerciaux, ingénieurs commerciaux,
                          ingénieurs d'affaires
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Toute personne
                          amenée à vendre un produit ou un service par téléphone
                          à un prospect
                        </li>
                      </ul>

                      <h4>Objectifs pédagogiques de cette formation :</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>Maîtriser les
                          entretiens par téléphone pour valoriser et entretenir
                          l'image de sa société
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Répondre avec
                          efficacité à tout type de demandes
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Gérer les
                          situations tendues et fidéliser le client avec méthode
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Apporter une
                          réponse précise et rapide en s'assurant de la
                          satisfaction de son interlocuteur
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h4>Méthodes pédagogiques :</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>Apports
                          théoriques et pratiques
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Réflexions de
                          groupe guidées par l'animateur
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>
                          Questionnaires-tests avec autocorrection
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Mise en situation
                          à partir du quotidien des participants (training ou
                          coaching en fonction de la demande)
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Élaboration d'un
                          Plan Personnel de Progrès
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Support de cours
                          déformation téléconseiller
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Tests et
                          exercices
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <hr></hr>

                {/*<PreRequis />*/}

                <ContenuCours />

                {/*Methodes pédagogiques*/}

                {/*<DateLieuxTable/>*/}

                <Author />
                <Comment />
                <Respond />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                <CourseSideDetail
                  csdcList={[
                    {
                      iconName: "icofont-book-alt",
                      leftText: "Référence",
                      rightText: "TEL.08",
                    },
                    {
                      iconName: "icofont-ui-alarm",
                      leftText: "Durée du cours",
                      rightText: "5 jours",
                    },
                    {
                      iconName: "icofont-price",
                      leftText: "Prix",
                      rightText: "2550 € HT",
                    },
                  ]}
                />
                <CourseAgenda />
                {/*<CourseSideCetagory />*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default FormationTeleVendeur;
