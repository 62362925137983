


const subTitle = "ÉDUCATION PROFESSIONNEL";
const title = <h2 className="title"><span className="d-lg-block">APPRENEZ LES</span> COMPÉTENCES DONT VOUS AVEZ BESOIN POUR<span className="d-lg-block">RÉUSSIR</span></h2>;
// const desc = "Des formations en ligne gratuites dispensées par les meilleurs experts mondiaux. rejoignez plus de 18 millions d'apprenants dès aujourd'hui.";
const desc = 'Notre objectif :faire de chaque parcours de formation professionnelle en relation client une expérience unique et de toute expérience un succes.';


const catagoryList = [
    {
        name: 'Figma',
        link: '#',
    },
    {
        name: 'Adobe XD',
        link: '#',
    },
    {
        name: 'illustration',
        link: '#',
    },
    {
        name: 'Photoshop',
        link: '#',
    },
]


const shapeList = [
    {
        name: '16M Students Happy',
        link: '#',
        className: 'ccl-shape shape-1',
    },
    {
        name: '130K+ Total Courses',
        link: '#',
        className: 'ccl-shape shape-2',
    },
    {
        name: '89% Successful Students',
        link: '#',
        className: 'ccl-shape shape-3',
    },
    {
        name: '23M+ Learners',
        link: '#',
        className: 'ccl-shape shape-4',
    },
    {
        name: '36+ Languages',
        link: '#',
        className: 'ccl-shape shape-5',
    },
]

const Banner = () => {
    return (
        <section className="banner-section">
            <div className="container">
                <div className="section-wrapper">
                    <div className="row align-items-center">
                        <div className="col-xxl-5 col-xl-6 col-lg-10">
                            <div className="banner-content">
                                {/*<h6 className="subtitle text-uppercase fw-medium">{subTitle}</h6>*/}
                                {title}
                                <p className="desc" style={{fontFamily:  'AvenirNextReg'}}>{desc}</p>


                                {/*}
                                <form action="/">
                                    <div className="banner-icon">
                                        <i className="icofont-search"></i>
                                    </div>
                                    <input type="text" placeholder="Mots clés de votre cours" />
                                    <button type="submit"> &nbsp;&nbsp;&nbsp;&nbsp;Rechercher&nbsp;&nbsp;</button>
                                </form>


                                <div className="banner-catagory d-flex flex-wrap">
                                    <p>Le plus populaire : </p>
                                    <ul className="lab-ul d-flex flex-wrap">
                                        {catagoryList.map((val, i) => (
                                            <li key={i}><a href={val.link}>{val.name}</a></li>
                                        ))}
                                    </ul>
                                </div>
                                {*/}

                            </div>
                        </div>
                        <div className="col-xxl-7 col-xl-6">
                            <div className="banner-thumb">
                                {/*<img src="assets/images/banner/04.jpeg" alt="img" />*/}
                                <img src="assets/images/banner/5.png" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="all-shapes"></div> */}
            <div className="cbs-content-list d-none">
                <ul className="lab-ul">
                    {shapeList.map((val, i) => (
                        <li className={val.className} key={i}><a href={val.link}>{val.name}</a></li>
                    ))}
                </ul>
            </div>
        </section>
    );
}
 
export default Banner;