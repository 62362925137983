import React from 'react';
import '../assets/css/MyStyle.css';


const ContenuFAQ = () => {
    return (
        <div className="course-video">
            <div className="course-video-content">
                <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion01">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist1" aria-expanded="true" aria-controls="videolist1"><span style={{cursor: "pointer" ,color:"#fff"}}>
                                    Quelle formation est la plus adaptée à mon besoin ?
                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                            {/** fa-solid fa-chevron-down */}
                        </div>
                        <div id="videolist1" className="accordion-collapse collapse" aria-labelledby="accordion01" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className="d-flex flex-wrap justify-content-between">






                                    <div className="video-item-title">
                                        <p>Plusieurs options s'offrent à vous :</p>
                                        <p>
                                            <ul>
                                                <li>Consultez nos formations commerciales si vous avez déjà une idée précise du sujet à travailler. Afin d'en savoir plus sur nos programmes de formations, n'hésitez pas à les consulter directement. Découvrez par exemple la formation closing commercial, ou encore celle conçue spécifiquement pour vos managers commerciaux.</li>

                                                <li>Auditez gratuitement votre équipe via notre outil d'évaluation à 360° pour identifer les leviers de progression de vos collaborateurs.</li>

                                                <li>Ou contactez-nous pour échanger sur votre projet. Nos experts sauront vous conseiller et vous concocter un programme sur-mesure si besoin.</li>

                                            </ul>
                                        </p>



                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <div className="accordion-header "  id="accordion02">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist2" aria-expanded="true" aria-controls="videolist2"> <span style={{cursor: "pointer" ,color:"#fff"}}>
                                Peut-on financer la formation avec notre OPCO ?</span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                        </div>
                        <div id="videolist2" className="accordion-collapse collapse" aria-labelledby="accordion02" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">
                                        <p>
                                            Oui, nos formations à distance et en présentiel sont éligibles aux financements des OPCO. Contactez-nous directement pour réaliser une simulation de financement.
                                        </p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion03">
                            <button  className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist3" aria-expanded="true" aria-controls="videolist3"><span style={{cursor: "pointer",color:"#fff" }}>
                                Sont-elles éligibles au CPF ?
                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button >
                        </div>
                        <div id="videolist3" className="accordion-collapse collapse" aria-labelledby="accordion03" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">
                                        <p>
                                            Nos formations ne sont pas encore éligibles au Compte Personnel de Formation (CPF). Celles-ci sont destinées aux entreprises avec des financements possibles par COTRACALL.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion04">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist4" aria-expanded="true" aria-controls="videolist4"><span style={{cursor: "pointer",color:"#fff", }}>
                                Quels programmes proposez-vous ?
                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                        </div>
                        <div id="videolist4" className="accordion-collapse collapse" aria-labelledby="accordion04" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">
                                        <p>
                                            Nous accompagnons vos équipes dans leur montée en compétences sur 3 leviers :

                                        </p>
                                        <p>
                                            <ul>
                                                <li>Les techniques de vente : ils apprendront les meilleurs outils, astuces et techniques de vente du moment pour progresser sur toutes les phases du cycle de vente (de la prospection au closing).</li>

                                                <li>La posture commerciale : ils apprendront à adopter une posture de leadership et à renforcer leur mental.</li>

                                                <li>Le management : vos managers apprendront à entraîner, motiver, inspirer et faire progresser leurs équipes de vente.</li>

                                            </ul>

                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion05">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist5" aria-expanded="true" aria-controls="videolist5"> <span style={{cursor: "pointer",color:"#fff", }}>
                               Quelle formation est la plus adaptée à mon besoin ?
                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                        </div>
                        <div id="videolist5" className="accordion-collapse collapse" aria-labelledby="accordion05" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">


                                        <p>
                                            Plusieurs options s'offrent à vous :

                                        </p>
                                        <p>
                                            <ul>
                                                <li>Consultez nos formations commerciales si vous avez déjà une idée précise du sujet à travailler. Afin d'en savoir plus sur nos programmes de formations, n'hésitez pas à les consulter directement. Découvrez par exemple la formation closing commercial, ou encore celle conçue spécifiquement pour vos managers commerciaux.</li>

                                                <li>Auditez gratuitement votre équipe via notre outil d'évaluation à 360° pour identifer les leviers de progression de vos collaborateurs.</li>

                                                <li>Ou contactez-nous pour échanger sur votre projet. Nos experts sauront vous conseiller et vous concocter un programme sur-mesure si besoin.</li>

                                            </ul>
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion06">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist6" aria-expanded="true" aria-controls="videolist6"> <span style={{cursor: "pointer",color:"#fff", }}>
                                Qu'est-ce qu'une formation commerciale avec COTRACALL ?
                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                        </div>
                        <div id="videolist6" className="accordion-collapse collapse" aria-labelledby="accordion06" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">
                                        <p>
                                            Nos formateurs s'adaptent à l'expérience professionnelle de votre équipe commerciale, aux spécificités de leur portefeuille clients, à la politique commerciale de votre entreprise ainsi qu'à la stratégie commerciale portée par la direction.
                                        </p>
                                        <p>
                                            Nos formations partent de vos objectifs commerciaux pour déclencher dans votre équipe les bonnes techniques commerciales (prospecter, négocier, traiter les objections, fidéliser).

                                        </p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion07">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist7" aria-expanded="true" aria-controls="videolist7"> <span style={{cursor: "pointer",color:"#fff", }}>

                                Pourquoi choisir COTRACALL plutôt qu'un autre organisme de formation ?


                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                        </div>
                        <div id="videolist7" className="accordion-collapse collapse" aria-labelledby="accordion07" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">
                                        <p>


                                            Spécialistes de la vente et des commerciaux depuis 15 ans, nous avons développé des formations professionnelles 100% factuelles, basées sur une pédagogie claire et des mises en situations concrètes, pour des résultats immédiats.
                                        </p>
                                        <p>
                                            Chaque formation commerciale est conçue en interne par nos experts de la vente à partir des routines des meilleurs commerciaux que nous rencontrons. Le but est de faire comprendre que la vente est une affaire de méthode et non d'improvisation, et de partager des pratiques simples et efficaces.

                                        </p>
                                        <p>
                                            Parce que la vente ne s'apprend pas dans les livres, nos formateurs sont tous d’anciens directeurs et managers commerciaux qui connaissent la réalité du terrain et sauront adapter leurs discours et le contenu en fonction votre entreprise , votre secteur d'activité et votre cycle de vente.

                                        </p>
                                        <p>
                                            En bonus, nous partageons à vos collaborateurs un support pédagogique percutant. Notre livre recense l’intégralité des techniques, routines et postures des meilleurs commerciaux sur les sujets clés de la vente (qualification, closing, outils, mental…).

                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion08">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist8" aria-expanded="true" aria-controls="videolist8"> <span style={{cursor: "pointer",color:"#fff", }}>
                               Avez-vous des références dans notre secteur ?
                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                        </div>
                        <div id="videolist8" className="accordion-collapse collapse" aria-labelledby="accordion08" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">
                                        <p>
                                            Nos clients en parlent mieux que nous !
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion09">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist9" aria-expanded="true" aria-controls="videolist9"> <span style={{cursor: "pointer",color:"#fff", }}>
                                Comment se déroule une formation ?
                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                        </div>
                        <div id="videolist9" className="accordion-collapse collapse" aria-labelledby="accordion09" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">
                                        <p>
                                            Selon vos préférences, la formation commerciale se déroule dans votre entreprise ou à distance. Notre formateur procèdent d'abord à une évaluation de chaque participant. Puis, il déploie en physique le programme de techniques de vente et de montée en compétences. À la fin de la formation, nous évaluons une nouvelle fois les acquis des participants à l'aide d'un second assessment et d'une prise d'engagement formelle. Nous garantissons ainsi à chaque client un véritable impact et changement d'habitude dans leur équipe commerciale.
                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="accordion-item">
                        <div className="accordion-header" id="accordion10">
                            <button   className="d-flex flex-wrap justify-content-between" data-bs-toggle="collapse" data-bs-target="#videolist10" aria-expanded="true" aria-controls="videolist10"> <span style={{cursor: "pointer",color:"#fff", }}>

                                Qui sont les formateurs COTRACALL ?

                            </span>
                                <span>
                                    <i className="fa fa-solid fa-caret-down"></i> 
                                    {/*<i className="fa fa-caret-square-o-down"></i>*/}
                                </span>
                            </button>
                        </div>
                        <div id="videolist10" className="accordion-collapse collapse" aria-labelledby="accordion10" data-bs-parent="#accordionExample">
                            <ul className="lab-ul video-item-list">
                                <li className=" d-flex flex-wrap justify-content-between">
                                    <div className="video-item-title">

                                        <p>


                                            Nos formateurs sont tous des passionnés de la vente qui partagent l'ADN d'excellence commerciale COTRACALL.
                                        </p>
                                        <p>
                                            Ce sont des professionnels reconnus pour leur carrière dans la gestion de force de vente ; et comme si cela ne suffisait pas, ils sont aussi choisis pour leur qualité de showman (c'est pas pour rien que vous pouvez en retrouver sur scène... réductions possibles si on travaille ensemble 😉).

                                        </p>

                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ContenuFAQ;