const subTitle = "À PROPOS DE NOUS";
// const title = "COTRACALL Academy, vers la transformation de la relation client! ";
const title = "Une autre manière de travailler la relation client! ";
// const desc = "Fournissez un accès distinct aux utilisateurs mufuncto tandis que des processus transparents encouragent parfois des fonctionnalités efficaces plutôt qu'une architecture extensible communiquent des services à effet de levier et multiplateformes.";
const descriptions =
    [
        "Créé en 2007, COTRACALL  est un organisme de formation professionnelle spécialisé dans les métiers de la Relation client « au téléphone ? ».",
        "COTRACALL Academy est le pôle dédié à la formation continue de Solviseo Group.",
        "Nos programmes de formation sont conçus pour tous secteurs de la relation client assurées par des professionnels, tous à la fois experts et praticiens dans leur domaine d’activité.",
        "L’apprentissage des techniques liées à la relation client téléphone par le coaching et le contact humain sont placées au cœur de notre philosophie. Nos formations se déroulent en présentiel sous l’encadrement d’un ou plusieurs intervenants experts de leurs disciplines, et possédant une longue expérience professionnelle.",
        "Notre approche pédagogique privilégie l’entraînement et la mise en pratique pour favoriser un meilleur apprentissage, ceci afin d’assurer des résultats opérationnels et immédiats."
    ]


const aboutList = [
    {
        imgUrl: 'assets/images/about/icon/01.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Instructeurs qualifiés',
        desc: 'Mises en situation de cas concrets d’entretien, ',
    },
    {
        imgUrl: 'assets/images/about/icon/02.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Obtenir un certificat',
        desc: 'Simulations enregistrées, ',
    },
    {
        imgUrl: 'assets/images/about/icon/03.jpg',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Jeux de rôles filmés',
        desc: ' Jeux de rôles filmés',
    },
    {
        imgUrl: 'assets/images/about/icon/04.png',
        imgAlt: 'about icon rajibraj91 rajibraj',
        title: 'Pratique et efficace',
        desc: ' Approche pratico-pratique et 100% opérationnelle',
    },
]


const About = () => {
    return (

        <>


                <div className="container">
                    <div
                        className="row justify-content row-cols-xl-1 row-cols-1 align-items-end flex-row-reverse text-center">
                        <div className="col">
                            <div className="about-right padding-tb">
                                <div className="section-header">
                                    <span className="subtitle" style={{textAlign:"center", textTransform:'uppercase'}}>{subTitle}</span>
                                    <h2 className="title" style={{textAlign:"center", marginBottom:'70px'}}>{title}</h2>
                                    {
                                        descriptions.map((m) =>
                                            // console.log({m})
                                            <p style={{textAlign:"justify", lineHeight:"25px", marginTop:'20px', fontSize:'16pt',fontFamily:  'AvenirNextReg'}}>{m}</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </div>




            <div className="about-section">
                <div className="container">
                    <div className="row justify-content-center row-cols-xl-2 row-cols-1 align-items-end flex-row-reverse">
                        <div className="col">
                            <div className="about-right padding-tb">
                                <div className="section-wrapper">
                                    <ul className="lab-ul">
                                        {aboutList.map((val, i) => (
                                            <li key={i}>
                                                <div className="sr-left">
                                                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                                                </div>
                                                <div className="sr-right">
                                                    <h5>{val.title}</h5>
                                                    <p>{val.desc}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="about-left">
                                <div className="about-thumb">
                                    <img src="assets/images/about/01.png" alt="about" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>

    );
}

export default About;