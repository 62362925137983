import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory__2";
import CourseSideDetail from "../component/sidebar/course-detail___2";
import Respond from "../component/sidebar/respond";
import PreRequis from "../CompoParts/PreRequis";
import ContenuCours from "../CompoParts/ContenuCours";
import DateLieuxTable from "../CompoParts/DateLieuxTable";
import CourseAgenda from "../CompoParts/CourseAgenda";
import ContenuHelpDesk from "./contenu-cours/Contenu-HelpDesk";

const FormationHelpDesk = () => {
  return (
    <Fragment>
      <Header />
      <PageHeaderTwo
        title="FORMATION RELATION CLIENTS DANS UN HELP DESK: Développer la communication d’un support technique."
        description
        formateur="Mr Ahmed MIR"
        typeFormation="Relation Clients"
        pdf="FORMATION COTRACALL-FORMATION RELATION CLIENT DANS 1 HELP DESK CPF3.pdf"
      />
      <div className="course-single-section padding-tb section-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="main-part">
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h3>
                        FORMATION RELATION CLIENTS DANS UN HELP DESK: Développer
                        la communication d’un support technique.
                      </h3>
                      <p>
                        La télévente est souvent associée aux call center (ou
                        centre d'appels ou encore plateforme d'appels
                        téléphoniques) qui centralisent l'ensemble des contacts
                        téléphoniques et assurent un traitement standard pour
                        chaque appel. Même si les entreprises tendent à assurer
                        une gestion commerciale des demandes via une interface
                        online, nombre d'entreprises disposent encore d'un
                        service commercial dédié à la télévente en réception
                        d'appel. Cette formation sur la télévente en réception
                        d'appels vous permettra de revoir les fondamentaux de la
                        vente dans le cadre d'un appel entrant.
                      </p>
                      {/* personnes concernées */}
                      <h4>Personnes concernées :</h4>

                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>Téléconseillers,
                          télévendeurs, conseillers clientèle à distance,
                          assistant(e)s ADV, vendeurs, commerciaux,
                          technico-commerciaux, ingénieurs commerciaux,
                          ingénieurs d'affaires
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Toute personne
                          amenée à vendre un produit ou un service par téléphone
                          à un prospect
                        </li>
                      </ul>

                      {/* Objectifs */}

                      <h4>Objectifs :</h4>

                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>
                          Appréhender les fondamentaux de la formation vente par
                          téléphone auprès de prospects
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>
                          Savoir prospecter par téléphone dans un fort contexte
                          concurrentiel
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>
                          Savoir se positionner face à un concurrent en place
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>
                          Maitriser les techniques d’argumentation en télévente
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>
                          Savoir défendre ses prix et ses conditions
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>
                          Planifier et suivre ses relances
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <PreRequis /> */}
                <ContenuHelpDesk />
                {/*Methodes pédagogiques*/}
                <div className="course-item">
                  <div className="course-inner">
                    <div className="course-content">
                      <h4>Méthodologies</h4>
                      <ul className="lab-ul">
                        <li>
                          <i className="icofont-tick-mark"></i>Exercices
                        </li>
                        <li>
                          <i className="icofont-tick-mark"></i>Tests
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <hr></hr>

                {/*<DateLieuxTable />*/}

                <Author />
                <Comment />
                <Respond />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar-part">
                <CourseSideDetail
                  csdcList={[
                    {
                      iconName: "icofont-book-alt",
                      leftText: "Référence",
                      rightText: "TEL.08",
                    },
                    {
                      iconName: "icofont-ui-alarm",
                      leftText: "Durée du cours",
                      rightText: "5 jours",
                    },
                    {
                      iconName: "icofont-price",
                      leftText: "Prix",
                      rightText: "2550 € HT ",
                    },
                  ]}
                />
                <CourseAgenda />
                {/*<CourseSideCetagory />*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default FormationHelpDesk;
