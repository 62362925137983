import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeaderTwo from "../component/layout/pageheader-2";
import Author from "../component/sidebar/author";
import Comment from "../component/sidebar/comment";
import CourseSideCetagory from "../component/sidebar/course-cetagory__2";
import CourseSideDetail from "../component/sidebar/course-detail___2";
import Respond from "../component/sidebar/respond";
import PreRequis from "../CompoParts/PreRequis";
import ContenuCours from "../CompoParts/ContenuCours";
import DateLieuxTable from "../CompoParts/DateLieuxTable";
import CourseAgenda from "../CompoParts/CourseAgenda";


const CourseSingle = () => {
    return (
        <Fragment>
            <Header />
            <PageHeaderTwo />
            <div className="course-single-section padding-tb section-bg">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="main-part">
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h3>Formation télévente en réception d'appels : développer ses techniques commerciales appels entrants</h3>
                                            <p>La télévente est souvent associée aux call center (ou centre d'appels ou encore plateforme d'appels téléphoniques) qui centralisent l'ensemble des contacts téléphoniques et assurent un traitement standard pour chaque appel. Même si les entreprises tendent à assurer une gestion commerciale des demandes via une interface online, nombre d'entreprises disposent encore d'un service commercial dédié à la télévente en réception d'appel. Cette formation sur la télévente en réception d'appels vous permettra de revoir les fondamentaux de la vente dans le cadre d'un appel entrant.</p>
                                            <h4>Objectifs pédagogiques de cette formation :</h4>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>Développer le chiffre d'affaires des télévendeurs en perfectionnant leurs techniques commerciales.</li>
                                                <li><i className="icofont-tick-mark"></i>Élaborer un guide d'entretien en appels entrants.</li>
                                                <li><i className="icofont-tick-mark"></i>Construire un argumentaire produit et préparer les réponses aux objections.</li>
                                                <li><i className="icofont-tick-mark"></i>Développer l'image de sa société en assurant une relation client professionnelle.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <PreRequis />
                                <ContenuCours />
                                {/*Methodes pédagogiques*/}
                                <div className="course-item">
                                    <div className="course-inner">
                                        <div className="course-content">
                                            <h4>Méthodes pédagogiques :</h4>
                                            <ul className="lab-ul">
                                                <li><i className="icofont-tick-mark"></i>Exposé interactif, alternance d'apports et de réflexions des participants.</li>
                                                <li><i className="icofont-tick-mark"></i>Echanges d'expériences.</li>
                                                <li><i className="icofont-tick-mark"></i>Exercices pratiques, jeux de rôle bâtis à partir de situations réelles proposées par les participants.</li>
                                                <li><i className="icofont-tick-mark"></i>Coaching sur poste.</li>
                                                <li><i className="icofont-tick-mark"></i>Support de cours formation télévente en réception d'appels.</li>
                                                <li><i className="icofont-tick-mark"></i>Auto évaluation préalable en amont de la formation.</li>
                                                <li><i className="icofont-tick-mark"></i>Evaluation des acquis en fin de formation.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <hr></hr>
                                
                                <DateLieuxTable/>

                                <Author />
                                <Comment />
                                <Respond />
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="sidebar-part">
                                <CourseSideDetail />
                                <CourseAgenda />
                                <CourseSideCetagory />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
}

export default CourseSingle;